.no-left-right-padding {
    padding-left: 0px;
    padding-right: 0px;
}

.btn-default {
    background-color: #dddddd;
}

.btn-default:hover {
  color: black;
  background-color: white;
  border-color: black;
  cursor: pointer;
}

.margin-top-sm {
    margin-top: 0px;
}

.default {
  background-color: #1C92C7;
  height: 60px;
  color: white;
  text-align: center;
  font-size: 32px;
  padding-top: 5px;
}

.default-height {
  height: 60px;
}

.infotech {
  background-color: #E9E9E9;
  height: 60px;
  color: #1a2251;
  text-align: center;
  font-size: 32px;
  padding-top: 5px;
}

.infotech-height {
  height: 80px;
}

.eis {
  background-color: #333333;
  height: 60px;
  color: #FFC149;
  text-align: center;
  font-size: 32px;
  padding-top: 5px;
}

.eis-height {
  height: 80px;
}

.dropzone {
    background: #f7f5f3;
    border-radius: 5px;
    border: 2px dashed #d6d1ce;
    border-image: none;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    vertical-align: middle;
}

.dropzone-md {
    background: #f7f5f3;
    border-radius: 5px;
    border: 2px dashed #d6d1ce;
    border-image: none;
    min-height: 200px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    vertical-align: middle;
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
}

.popup_inner_key {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  min-width: 450px;
  min-height: 370px;
  max-width: 1000px;
  max-height: 750px;
  height: auto;
  margin: auto;
  margin-top: 150px;
  background: white;
  overflow: hidden;
}

.uploaded-files-div {
  border: 1px solid #dddddd;
  background-color: #dddddd;
  width: 100%;
  min-height: 40px;
  border-radius: 0.25rem;
  margin: auto;
  padding: 5px;
  margin-bottom: 10px;
}

.trash-icon {
  cursor: pointer;
  float: right;
  padding: 5px;
}

.container-width {
  max-width: 1000px;
}

.margin-left-0 {
  margin-left: 0px;
}

.margin-left-1 {
  margin-left: 20px;
}

.margin-left-2 {
  margin-left: 40px;
}

.margin-left-3 {
  margin-left: 60px;
}

.margin-left-4 {
  margin-left: 80px;
}

.margin-left-5 {
  margin-left: 100px;
}

.font-weight-bold {
  font-weight: bold;
}

.font-size-es-head {
  font-size: 18px;
}

.row-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.row-padding-0 {
  padding-right: 0px;
  padding-left: 0px;
}

.is-invalid {
  background-color: #eac6c6;
}

.is-valid {
  background-color: #c5ecb3;
}

.footer {
    height: 50px;
}

.wrapper {
  min-height: calc(100vh - 70px);
}

.popup_inner_key {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  max-width: 400px;
  max-height: 250px;
  height: auto;
  margin: auto;
  margin-top: 200px;
  background: white;
  overflow: hidden;
}

.div-bottom {
  min-height: 80px;
  background-color: #ececec;
  position: fixed;
  display: block;
  bottom: 0;
  right: 0;
  width: 100%;
}

.es-container {
  border: 1px solid #dddddd;
  border-radius: 0.25rem;
  padding-left: 0px;
  padding-top: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-right: 0px;
}

.ts-container {
  border: 1px solid #dddddd;
  border-radius: 0.25rem;
  padding-left: 0px;
  padding-top: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  width: 98%;
}

@media (max-width: 767px) {
    .margin-top-sm {
        margin-top: 7px;
    }
}

@media (max-width: 1100px) {
  .offset-3 {
    margin-left: 10%;
  }
}

@media (max-width: 767px) {
  .offset-3 {
    margin-left: 0;
  }
}